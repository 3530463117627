import React from "react";
import MentalHealthCard from "../what/whatpage-card";
import i18n from "i18n-js";
const ResourceVideo = () => {
  return (
    <section id="mhh-card-bg-img">
      <MentalHealthCard mhtext1={i18n.t("resource-video-text1")} classred="grey-card" heading={i18n.t("resource-video-heading")} textclass="text-white-trapezoid"/>
    </section>
  );
};
export default ResourceVideo;
