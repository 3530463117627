import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import ResourceVideo from "../../components/resources/resources-video";
import ComingSoon from "../Work/Coming-soon";
const Video = () => {
  return (
    <Layout pageTitle="Video">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <ResourceVideo />
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default Video